.menu {
  height: 78px;
  background-color: #fff;
  .menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .menu-list {
    height: 78px;
    display: flex;
    flex-direction: row;
    li {
      a {
        text-decoration: none;
        color: #333;
        padding: 0 20px;
        height: 78px;
        line-height: 78px;
        display: block;
        font-size: 18px;
      }
    }
    .menu-list-active {
      background-color: #7173f2;
      color: #fff;
    }
  }
}

