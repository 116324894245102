.siteMapFooter {
  height: 569px;
  background-size: cover !important;
  .footer-content {
    padding-top: 196px;
    .content-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      ul {
        flex: 1;
        height: 140px;
        margin-left: 72px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li {
          img {
            margin-right: 15px;
          }
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .gongzhonghao {
      display: flex;
      width: 140px;
      flex-direction: column;
      text-align: center;
      margin-left: 40px;
      img{
        width: 140px;
        height: 140px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 10px;
      }
    }

    .content-bottom {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      ul {
        display: flex;
        flex-direction: row;
        margin-left: 240px;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        li {
          a {
            text-decoration: none;
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
  }
}

