body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: Microsoft YaHei;
  margin: 0;
  background: #f4f5f7 !important;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
  .safetyZone {
    width: 1200px;
    margin: 0 auto;
  }
  h4,
  h3 {
    margin: 0;
  }
  .w1200 {
    width: 1200px;
    margin: 0 auto;
  }
  .ant-empty {
    background-color: #fff;
    padding: 40px 0;
    width: 1200px;
    margin: 0 auto;
  }
  .ant-breadcrumb {
    margin: 10px 0;
  }
  .ant-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    span {
      color: #333333;
    }
    span[disabled] {
      color: #999;
    }
  }
  .ant-pagination-item-active {
    border-color: #7173f2;
    background-color: #7173f2;
    a {
      color: #fff;
    }
  }
  .ant-btn {
    border: none !important;
  }
  .spin-loading {
    height: calc(100vh - 78px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none !important;
  }

}

